import BookDemoButton from '@/@core/components/BookDemoButton';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import creditCard from '../../../public/images/landing/in-action/Credit_Card.svg';
import instantAccess from '../../../public/images/landing/in-action/InstantAccess.svg';
import support from '../../../public/images/landing/in-action/Support.svg';
import Image from 'next/image';

const data = [
  {
    heading: 'Instant Access',
    description: 'Begin exploring FinanceOp’s features immediately',
    logo: instantAccess,
  },
  {
    heading: 'No Commitments',
    description: 'No credit card required, and you can cancel anytime',
    logo: creditCard,
  },
  {
    heading: 'Dedicated Support',
    description: 'Our team is here to assist you every step of the way during your trial.',
    logo: support,
  },
];

const InAction = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        background: '#fff',
        py: '80px',
      }}
    >
      <Box
        sx={{
          width: '1000px',
          maxWidth: '90vw',
          margin: '0 auto',
          overflow: 'hidden',
          display: 'flex',
          background: 'linear-gradient(180deg, #6461F3 0%, #5552CB 23.5%, #1F1933 100%)',
          borderRadius: '32px',
          padding: '32px',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          ...(isMobile && {
            flexDirection: 'column',
            gap: '30px',
          }),
        }}
      >
        <Box sx={{ width: isMobile ? '100%' : '45%' }}>
          <Typography
            sx={{
              color: '#fff',
              fontWeight: '500',
              fontSize: '3.2rem',
              '& .MuiButtonBase-root ': {
                width: '150px',
              },
            }}
          >
            Ready to see <br />
            FinanceOps in action?
          </Typography>
          <Box
            sx={{
              mt: '45px',
            }}
          >
            <BookDemoButton text={'Start free trial'} variant='new' />
          </Box>
        </Box>
        <Box sx={{ width: isMobile ? '100%' : '45%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {data.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  padding: '16px',
                  border: '1px solid rgba(255, 255, 255, 0.20)',
                  background:
                    'linear-gradient(90deg, rgba(255, 255, 255, 0.08) 6.01%, rgba(255, 255, 255, 0.17) 90.83%)',
                  display: 'flex',
                  gap: '12px',
                  alignItems: 'center',
                  borderRadius: '12px',
                }}
              >
                <Box
                  sx={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    padding: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <Image src={item.logo} alt={item.heading} />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1.6rem',
                      fontWeight: '600',
                      color: '#fff',
                      pb: '5px',
                    }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: '400',
                      color: 'rgba(255, 255, 255, 0.70)',
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default InAction;
