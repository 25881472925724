import React, { useEffect, useRef, useState } from 'react';
import OurSolutions from '@/components/landing-page/OurSolutions';
import Hero from '@/components/landing-page/Hero';
import { Box, Modal, Typography, Button, TextField, Grid, useMediaQuery, useTheme } from '@mui/material';
import Faqs from '@/components/landing-page/FAQs';
import Footer from '@/@core/components/Footer';
import OurUsers from '@/components/landing-page/OurUsers';
import Integrations from '@/components/landing-page/Integrations';
import InAction from '@/components/landing-page/InAction';
import AiAgent from '@/components/landing-page/Agent';
import contactForm from '../../public/images/contact-form.svg';
import contactFormCheck from '../../public/images/contact-check.svg';
import Image from 'next/image';
import emailjs from '@emailjs/browser';
import CloseIcon from '@mui/icons-material/Close';

const Home = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({ name: '', email: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const integrationsRef = useRef(null);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down(1000));
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          const alreadyShown = localStorage.getItem('showContactForm');
          if (alreadyShown) {
            return;
          }
          localStorage.setItem('showContactForm', 'true');
          setOpen(true);
        }
      },
      { threshold: 0.5 },
    );

    if (integrationsRef.current) {
      observer.observe(integrationsRef.current);
    }

    return () => {
      if (integrationsRef.current) {
        observer.unobserve(integrationsRef.current);
      }
    };
  }, []);

  const handleClose = () => setOpen(false);

  const handleErrorName = () => {
    if (!name) {
      setError(prev => ({ ...prev, name: 'Name is required' }));
    } else {
      setError(prev => ({ ...prev, name: '' }));
    }
  };

  const handleErrorEmail = () => {
    if (!email) {
      setError(prev => ({ ...prev, email: 'Email is required' }));
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError(prev => ({ ...prev, email: 'Email is not valid' }));
    } else {
      setError(prev => ({ ...prev, email: '' }));
    }
  };

  const sendEmail = async () => {
    try {
      const result = await emailjs.send(
        process.env.NEXT_PUBLIC_EMAILJS_SERVICE_ID || '',
        process.env.NEXT_PUBLIC_EMAILJS_TEMPLATE_ID || '',
        { name, email, companyName: '', domain: '', openedFrom: 'scroll-modal' },
        { publicKey: process.env.NEXT_PUBLIC_EMAILJS_PUBLIC_KEY },
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = () => {
    if (!error.name && !error.email) {
      console.log('Form Submitted:', { name, email });
      sendEmail();
      setFormSubmitted(true);
    }
  };

  return (
    <Box
      sx={{
        '& .common-footer-container': {
          mt: '0 !important',
        },
      }}
    >
      <Hero />
      <AiAgent />
      <Box ref={integrationsRef}>
        <Integrations />
      </Box>
      <OurSolutions />
      <InAction />
      <OurUsers />
      <Faqs />
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
        BackdropProps={{
          onClick: event => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 930,
            bgcolor: 'background.paper',
            borderRadius: 6,
            boxShadow: 24,
            display: 'flex',
            alignItems: 'center',
            maxWidth: '90vw',
            outline: 'none !important',
            maxHeight: '506px',
          }}
        >
          <Box
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '16px',
              top: '16px',
              cursor: 'pointer',
            }}
          >
            <CloseIcon
              sx={{
                fontSize: '24px',
              }}
            />
          </Box>
          {formSubmitted && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: '112px',
              }}
            >
              <Image alt='check' src={contactFormCheck} />
              <Typography
                sx={{
                  fontSize: '2.4rem',
                  color: '#170F49',
                  pt: '77px',
                  pb: '8px',
                  fontWeight: '700',
                }}
              >
                Thank you!
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.8rem',
                  color: '#6F6C90',
                  fontWeight: '400',
                }}
              >
                We will reach out to you soon!
              </Typography>
            </Box>
          )}
          {!formSubmitted && (
            <>
              {!isSmallDevice && <Image src={contactForm} alt='form image' width={400} />}
              <Box
                sx={{
                  flexGrow: '1',
                  minWidth: isSmallDevice ? '90%' : '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    maxWidth: '364px',
                    ...(isSmallDevice && {
                      p: '16px',
                    }),
                  }}
                >
                  <Typography
                    id='modal-title'
                    variant='h6'
                    component='h2'
                    sx={{
                      color: '#170F49',
                      fontSize: '2.3rem',
                      fontWeight: '600',
                    }}
                  >
                    Witness GenesisOne in Action
                  </Typography>
                  <Typography
                    id='modal-description'
                    sx={{
                      color: '#6F6C90',
                      fontSize: '1.8rem',
                      fontWeight: '400',
                      pt: '16px',
                      pb: '38px',
                    }}
                  >
                    Request a demo and we will have someone reach out to you!
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      '& .MuiInputBase-input': {
                        boxShadow: '0px 2px 6px 0px rgba(19, 18, 66, 0.07)',
                        borderRadius: '16px',
                        border: 'none',
                      },
                      '& fieldset': {
                        border: ' 1px solid #EFF0F6',
                        borderRadius: '16px',
                      },
                      '& .MuiInputBase-root': {
                        '&:hover': {
                          '& fieldset': {
                            border: `2px solid ${theme.palette.primary.main}`,
                            borderRadius: '16px',
                          },
                        },
                      },
                    }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        placeholder='Name'
                        fullWidth
                        value={name}
                        onChange={e => setName(e.target.value)}
                        error={!!error.name}
                        helperText={error.name}
                        onBlur={handleErrorName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder='Email'
                        fullWidth
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={!!error.email}
                        helperText={error.email}
                        onBlur={handleErrorEmail}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={handleSubmit}
                        variant='contained'
                        color='primary'
                        fullWidth
                        sx={{
                          fontWeight: '700',
                          fontSize: '1.8rem',
                          borderRadius: '16px',
                        }}
                      >
                        Request Demo
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
